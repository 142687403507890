<template>
  <div>
    <b-card class="border-0">
      <div class="mt-3 mb-4">
        <h4 class="text-center">Inserisci i dati dell'azienda di cui vuoi ottenere informazioni</h4>
      </div>
      <b-row>
<!--        <b-col lg="6" xl="4" offset-xl="2">-->
<!--          <div class="custom-input-group has&#45;&#45;append">-->
<!--            <field-text-->
<!--              fieldId="companyName"-->
<!--              placeholder="Ragione Sociale"-->
<!--              v-model="currentSelectionName"-->
<!--              lg-->
<!--              :state="null"-->
<!--              class="flex-grow-1"-->
<!--            />-->
<!--            <div class="custom-input-group__append">-->
<!--              <b-button-->
<!--                variant="info"-->
<!--                @click="openInfo"-->
<!--                size="lg"-->
<!--              >-->
<!--                <font-awesome-icon icon="info-circle"/>-->
<!--              </b-button>-->
<!--            </div>-->
<!--          </div>-->
<!--        </b-col>-->
<!--        <b-col lg="6" xl="4">-->
<!--          <field-text-->
<!--            fieldId="taxCode"-->
<!--            placeholder="Partita Iva"-->
<!--            v-model="currentSelectionTaxCode"-->
<!--            lg-->
<!--            :state="null"-->
<!--          />-->
<!--        </b-col>-->
        <b-col md="6" lg="4" class="mx-md-auto">
          <div class="custom-input-group has--append">
            <field-text
              fieldId="taxCode"
              placeholder="Partita Iva"
              v-model="currentSelectionTaxCode"
              lg
              :state="null"
              class="flex-grow-1"
            />
            <div class="custom-input-group__append">
              <b-button
                variant="info"
                @click="openInfo"
                size="lg"
              >
                <font-awesome-icon icon="info-circle"/>
              </b-button>
            </div>
          </div>
        </b-col>
      </b-row>
      <b-row class="my-3">
        <b-col md="6" xl="4" class="mx-md-auto">
          <div :class="searchButtonWrapperClasses">
            <b-button
              size="lg"
              variant="info"
              block
              :disabled="!hasSearchData"
              @click="openSearchModal"
            >
              ESEGUI LA RICERCA
            </b-button>
          </div>
        </b-col>
      </b-row>
    </b-card>
    <b-card
      class="border-0 mt-4 mb-5"
      v-if="hasSearchResults"
      body-class="pb-0"
    >
      <b-row>
        <b-col
          sm="6"
          md="4"
          xl="3"
          v-for="item in searchList"
          :key="item.companyId"
          :class="computedCardClass(searchList.length)"
        >
          <profile-search-data-search-item
            :data="item"
          />
        </b-col>
      </b-row>
    </b-card>
    <b-card v-if="hasError" class="mt-5 my-4 text-center border-0">
      <p>
        <em>Si è verificato un errore nella ricerca, riprova più tardi</em>
      </p>
    </b-card>
    <profile-search-data-search-modal
      :id="searchModalId"
      :searchFunction="doQuickSearch"
      :searchData="{
        name: currentSelectionName,
        taxCode: currentSelectionTaxCode,
        }"
    />
    <profile-search-data-search-modal-info :id="infoModalId" />
  </div>
</template>

<script>
import { isNotEmpty, isPresent } from '../../utils/validators';

const FieldText = () => import('../fields/FieldText.vue');
const ProfileSearchDataSearchItem = () => import('./search/ProfileSearchDataSearchItem.vue');
const ProfileSearchDataSearchModal = () => import('./search/ProfileSearchDataSearchModal.vue');
const ProfileSearchDataSearchModalInfo = () => import('./search/ProfileSearchDataSearchModalInfo.vue');

export default {
  name: 'ProfileSearchDataSearch',
  components: {
    ProfileSearchDataSearchModalInfo,
    ProfileSearchDataSearchModal,
    ProfileSearchDataSearchItem,
    FieldText,
  },
  props: {},
  data() {
    return {
      options: [],
      currentSelectionName: '',
      currentSelectionTaxCode: '',
      hasError: false,
      hasSearchResults: false,
      companyName: '',
      dataTax: '',
    };
  },
  computed: {
    searchButtonWrapperClasses() {
      const a = '';
      if (!this.hasSearchData) return `${a} is-disabled`;
      return a;
    },
    searchList() {
      return this.$store.getters['subject/quickSearchResults'];
    },
    hasSearchData() {
      // console.log(
      //   'isNotEmpty(this.currentSelectionName) && isNotEmpty(this.currentSelectionTaxCode)',
      //   isNotEmpty(this.currentSelectionName) || isNotEmpty(this.currentSelectionTaxCode),
      //   this.currentSelectionName,
      //   this.currentSelectionTaxCode,
      // );
      return isPresent(this.currentSelectionName) || isPresent(this.currentSelectionTaxCode);
    },
    searchModalId() {
      return 'searchModal';
    },
    infoModalId() {
      return 'infoModal';
    },
  },
  methods: {
    onSearch(search, loading) {
      console.log('searching?');
      if (search.length) {
        console.log('should be searching');
        loading(true);
        this.search(loading, search, this);
      }
    },
    onComplete() {
      this.$router.replace(`/crp-profile/${this.entityType}/${this.dataTax}`);
    },
    doQuickSearch() {
      this.$bvModal.hide(this.searchModalId);

      this.hasSearchResults = false;
      this.hasError = false;
      const currentProfile = this.$store.getters['subject/currentCompany'];
      let ownerType;
      let ownerId;

      if (isNotEmpty(currentProfile)) {
        ownerType = 'company';
        ownerId = currentProfile.companyId;
      } else {
        ownerType = 'person';
        ownerId = this.$store.getters['subject/currentPerson'].personId;
      }

      const reportRequest = {
        ownerType,
        ownerId,
        taxCode: this.currentSelectionTaxCode,
        companyName: this.currentSelectionName,
      };

      this.$store.dispatch('subject/quickCompanySearch', reportRequest)
        .then((subject) => {
          console.log('habemus search', subject);
          this.hasSearchResults = true;
        },
        (error) => {
          console.error('Errore in ricerca club virtuosi', error);
          this.hasError = true;
        });
    },
    openSearchModal() {
      // this.$confirm(
      //   {
      //     message: 'Attenzione: ogni ricerca prevede un addebito pari a 1CRP, indipendemente dal risultato della ricerca. Continuare?',
      //     button: {
      //       no: 'No',
      //       yes: 'Si',
      //     },
      //     /**
      //      * Callback Function
      //      * @param {Boolean} confirm
      //      */
      //     callback: (confirm) => {
      //       if (confirm) {
      //         this.doQuickSearch();
      //       }
      //     },
      //   },
      // );
      // console.log('seearchModal?');
      this.$bvModal.show(this.searchModalId);
    },
    computedCardClass(len) {
      // console.log('len', len);
      if (len === 1) return 'mx-auto mb-3';
      return 'mb-3';
    },
    openInfo() {
      this.$bvModal.show(this.infoModalId);
    },
  },
};
</script>

<style scoped>

</style>
